<template>
    <main id="liste" v-if="tiers">
        <!-- A changer par le nom du tiers sur lequel on effectue les opérations de compta -->
        <HeaderTab 
            :title="tiers.tiers_rs" 
            :tiers="tiers" 
            :element="tiersElement" 
            :swiper_tabs="swiper_tabs" 
            :show_tag="true"
        />

        <div id="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <router-view :tiers.sync="$cache.tiers" @change="loadTiers"></router-view>
                    </div>
                </div>
            </div>
        </div>
        <b-modal ref="modal_archive_tiers" hide-footer >
            <template v-slot:modal-title>
                {{ $t("action.confirmation_archive_radie_title") }}
            </template>
            <p>
                {{ $t("action.confirmation_archive_radie_content") }}
            </p>
            <div class="row justify-content-around">
                <b-button class="mt-3 btn-block rounded-pill col-5" variant="primary" @click="archive">{{ $t("global.oui") }}</b-button>
                <b-button class="mt-3 btn-block rounded-pill col-5" variant="primary" @click="closeModalArchiveTiers">{{ $t("global.non") }}</b-button>
            </div>
        </b-modal>
    </main>
    <main v-else>
        <LoadingSpinner v-if="tier_exist" class="col-12" />
        
        <div v-else class="box">
            <div class="alert alert-info">
                {{ $t('global.tier_introuvable') }}. <router-link :to="{ name: 'tiersListe' }">{{ $t('global.retourner_sur_liste') }}</router-link>
            </div>
        </div>
       
    </main>
</template>


<script type="text/javascript">
    import Vue from 'vue'
    import Countries from '@/assets/lang/countries/countries'
    import Navigation from "@/mixins/Navigation.js"
    import Tiers from "@/mixins/Tiers.js"
    import Payment from "@/mixins/Payment.js"
    import Notes from "@/mixins/Notes.js"

	export default {
		name: "tiersFiche",
		mixins: [Navigation, Tiers, Payment, Notes],
		data () {
			return {
                has_notes:false,
                param_tab: '',
                actual_tab: 'tiersTableauBord',
                default_tab: 'tiersTableauBord',
                tiers: null,
                tiers_id: 0,
                tier_exist: true,
                hubspot_id: null,
                groups: [],
				tabsToReload: [],
                asked_archive: false,
                events_tab: {
                    "TiersFiche::ReloadTabs": (params) => {
                        this.reloadTabs(params)
                    },
                }
			}
		},

		mounted() {
            // this.param_tab = this.$route.params.actual_tab

            this.tiers_id = this.$route.params.tiers_id * 1

            this.init_component()
		},
        beforeDestroy() {
            this.$cache.tiers = null
        },
		methods: {
            async init_component() {
                this.loadTiers()
                this.hasNotes()
            },
            async loadTiers() {
				this.tiers = null

                // je dois avoir un id positif pour appeler l'API
                if(this.tiers_id > 0) {
                    try {
                        this.groups = await this.loadTiersGroup(this.tiers_id);
                    }
                    catch(e){
                        this.tier_exist = false
                    }

                    if(this.hasHubspotAccess) {
                        let hubspot = await this.getHubspotId(this.tiers_id)
                        if(hubspot.hubspot_id) {
                            this.hubspot_id = hubspot.hubspot_id
                        }
                    }
                }

                this.tiers = await this.loadTier(this.tiers_id)

                if(this.hubspot_id) {
                    this.tiers.hubspot_id = this.hubspot_id
                }
                this.$cache.tiers = this.tiers

                if(this.tiers.tiers_paymentmethod) {
                    const payment_method = await this.loadPaymentMethod(this.tiers.tiers_paymentmethod)

                    if(payment_method) {
                        if(payment_method.paymentmethod_code == 'GCL')
                            this.tiers.is_gocardless = true
                    }
                }
                document.title = this.tiers ? this.tiers.tiers_rs : "Groomy"

                if (this.tiers && this.tiers.tiers_archive == 0 && !this.asked_archive &&(this.tiers.tiers_statut_rcs === "Radié" || this.tiers.tiers_statut_rcs === "Struck off")){
                    this.$nextTick(()=>{
                        this.$refs.modal_archive_tiers.show()
                        this.asked_archive = true
                    })
                }
            },
            reloadTabs(tab){
                if(this.tabsToReload.indexOf(tab) == -1) {
                    this.tabsToReload.push(tab)
                }
			},
            updateActualTab(href) {
                this.actual_tab = href
                if(this.$route.name == 'tiersFiche')
                    this.$router.replace({ name: this.actual_tab })
            },
            async hasNotes(){
                const end = new Date()
                const start = new Date(new Date().setMonth(new Date().getMonth() - 3))
                const notes = await this.getTiersNotes(this.tiers_id, start.toDateInputValue(), end.toDateInputValue())
                this.has_notes = notes.length > 0
            },
            archive() {
                this.archiveTiers(this.tiers.tiers_id)
                this.$refs.modal_archive_tiers.hide()
                this.successToast()
                this.$router.push({ name: 'tiersListe' })
		    },
            closeModalArchiveTiers(){
                this.$refs.modal_archive_tiers.hide()
            },
		},
        
        computed:{
            tiersElement() {
                if(!this.tiers) { return [] }

                let address = [
                    this.tiers.tiers_address1,
                    this.tiers.tiers_address2,
                    `${this.tiers.tiers_postalcode} ${(this.tiers.tiers_town || '').toUpperCase()}`.trim(),
                    Countries[Vue.i18n.locale()].label[this.tiers.tiers_country]
                ].filter(Boolean).join(', ')

                let has_group = false
                if(this.groups.length > 0){
                    has_group = true
                }

                let temp = {
                    'media_filename' : '',
                    'alt' : '',
                    'subtitle' : address,
                    'has_group': has_group,
                    'groups': this.groups
                }
                return temp
            },

            swiper_tabs() {
                const config = this.getConfig('tabs_config')
                const config_tabs = config['tiers'][1].length > 0 ? config['tiers'][1][0].tabs : config['tiers'][0][0].tabs

                let tabs = [{
                        id: 'tableau',
                        label: 'tiers.menu_tableau_bord',
                        active: false,
                        href: 'tiersTableauBord'
                    },
                    {
                        id: 'coordonnees',
                        label: 'tiers.menu_coordonnees',
                        active: false,
                        href: 'tiersCoordonnees'
                    },
                    {
                        id: 'juridique',
                        label: 'tiers.menu_juridique',
                        active: false,
                        href : 'tiersJuridique',
                    },
                    {
                        id: 'contacts',
                        label: 'tiers.menu_contacts',
                        active: false,
                        href : 'tiersContact',
                        hidden : !this.hasEntitiesAccess
                    },
                    {
                        id: 'chevaux',
                        label: 'tiers.menu_chevaux',
						href : 'tiersHorse',
                        active: false,
						dropdown: 
						[
							{
								label: 'horse.owned',
								href : 'tiersHorse',
								hidden: false,
								active: false,
							},
							{
								label: 'horse.secondary',
								href : 'tiersHorseSecondaires',
								hidden: false,
								active: false,
							},
							{
								label: 'horse.exterieur',
								href : 'tiersHorseExterieur',
								hidden: false,
								active: false,
							}
						]
                    },
					{
                        id: 'contrats',
                        label: 'tiers.menu_monte',
                        active: false,
                        href : 'tiersMonte',
                        hidden: !this.hasMonteAccess,
						dropdown: [{
								label: 'tiers.menu_contrats',
								href : 'tiersContracts',
								hidden: false,
								active: false,
							},
							{
								label: 'tiers.menu_lieu_monte',
								href : 'tiersLieuMonte',
								hidden: false,
								active: false,
							},
							{
								label: 'tiers.menu_monte_resa',
								href : 'tiersMonteReservation',
								hidden: false,
								active: false,
							},
							{
								label: 'tiers.menu_courtier',
								href : 'tiersCourtier',
								hidden: false,
								active: false,
							},
                            {
                                label: 'global.syndication',
                                href : 'TirersSyndication',
                                hidden: false,
                                active: false,
                            },
                            {
                                label: 'tiers.stock_semence_distant',
                                href : 'tiersSemence',
                                hidden: !this.hasSemenceAccess,
                                active: false,
                            }
						]
					},
      //               {
      //                   id: 'facturation',
      //                   label: 'tiers.menu_facturation',
      //                   active: false,
      //                   href : 'tiersInvoice',
						// hidden: !this.hasFacturationAccess
      //               },
                    {
                        id: 'facturation',
                        label: 'tiers.menu_facturation',
                        href : 'tiersInvoice',
                        active: false,
                        hidden: !this.hasFacturationAccess,
                        dropdown: [
                            {
                                label: 'invoice.titre_a_valider',
                                href : 'tiersAValider',
                                hidden: false,
                                active: false,
                            },
                            {
                                label: 'invoice.titre_bon_a_facturer',
                                href : 'tiersBons',
                                hidden: false,
                                active: false,
                            },
                            {
                                label: 'tiers.menu_facturation',
                                href : 'tiersInvoice',
                                hidden: false,
                                active: false,
                                default: true
                            }
                        ]
                    },
                    {
                        id: 'reglements',
                        label: 'tiers.menu_reglements',
                        active: false,
                        href : 'tiersPayment',
						hidden: !this.hasFacturationAccess
                    },
                    {
                        id: 'parametres',
                        label: 'tiers.menu_parametres',
                        active: false,
                        href : 'tiersParameters',
						hidden: !this.hasFacturationAccess
                    },
                    {
                        id: 'qualifications',
                        label: 'qualification.qualifications',
                        active: false,
                        href : 'tiersQualifications',
                    },
                    {
                        id: 'document',
                        label: 'tiers.documents',
                        active: false,
                        href : 'tiersDocuments',
                    },
                    {
                        id: 'facture_fournisseur',
                        label: 'tiers.factures_fournisseurs',
                        active: false,
                        href : this.invoice_supplier_href,
						hidden: !this.hasInvoiceSupplier && !this.hasInvoiceSupplierOCR,
                        dropdown: 
                        [
                            {
                                label: 'tiers.factures_fournisseurs',
                                href : 'tiersSuppliersInvoices',
                                hidden: !this.hasInvoiceSupplier,
                                active: false,
                            },
                            {
                                label: 'invoice.supplier_control',
                                href : 'tiersInvoiceSupplierControl',
                                hidden: !this.hasInvoiceSupplierOCR,
                                active: false,
                            },
                            {
                                label: 'invoice.supplier_checked',
                                href : 'tiersInvoiceSupplierChecked',
                                hidden: !this.hasInvoiceSupplierOCR,
                                active: false,
                            },
                            {
                                label: 'invoice.supplier_payments',
                                href : 'tiersInvoiceSupplierPayment',
                                hidden: !this.hasInvoiceSupplierOCR,
                                active: false,
                            }
                        ]
                    },
                    {
                        id: 'courrier_perso',
                        label: 'courrier_type.title',
                        active: false,
                        href : 'tiersCourrierPerso',
                    },
                    {
                        id: 'groups',
                        label: 'tiers.menu_groupe',
                        active: false,
                        href : 'tiersGroups',
                    },
                    {
                        id: 'devis',
                        label: 'tiers.devis',
                        active: false,
                        href : 'tiersQuotation',
                        hidden: !this.hasDevisAccess
                    },
                    {
                        id: 'localisation',
                        label: 'tiers.localisation',
                        active: false,
                        href : 'tiersLocalisation'
                    },
                    {
						label: this.has_notes ? 'global.with_notes' : 'global.notes',
						href : 'tiersFicheNotes',
                        hidden: !this.hasNoteAccess,
						active: false
					},
                    {
						label: 'global.groomy',
						href : 'tiersAdmin',
                        hidden: !this.hasAdminAccess,
						active: false
					}
                ]

                let res_tab = config_tabs.map(tab => tabs.find(t => {
                    if(t.href == 'tiersInvoiceSupplierControl') {
                        return tab == 'tiersSuppliersInvoices'
                    }
                    return t.href == tab
                })).filter(tab => tab)
                
                res_tab.push({
                    label: '',
                    icon: ['fal', 'cogs'],
                    href : 'tabConfig',
                    params: {type: 'tiers', from: this.$route.path},
                    active: false
                })

                res_tab[0].active = true

                this.updateActualTab(res_tab[0].href)

                return res_tab
            },
			hasFacturationAccess() {
				return this.$store.state.userAccess.hasFacturationAccess
			},
            hasMonteAccess() {
                return this.$store.state.userAccess.hasSaillieAccess
            },
            hasEntitiesAccess() {
                return this.$store.state.userAccess.hasEntitiesAccess
            },
            hasHubspotAccess() {
                return this.$store.state.userAccess.hasHubspotAccess
            },
            hasDevisAccess() {
                return this.$store.state.userAccess.hasDevisAccess
            },
            hasSemenceAccess() {
                return this.$store.state.userAccess.hasSemenceAccess
            },
            hasNoteAccess() {
                return this.$store.state.userAccess.hasNoteAccess
            },
            hasAdminAccess() {
                return this.$store.state.userAccess.hasAdminAccess
            },
            hasInvoiceSupplier() {
                return this.$store.state.userAccess.hasInvoiceSupplier
            },
            hasInvoiceSupplierOCR() {
                return this.$store.state.userAccess.hasInvoiceSupplierOCR
            },
            invoice_supplier_href() {
                if(this.hasInvoiceSupplier) {
                    return 'tiersSuppliersInvoices'
                }
                if(this.hasInvoiceSupplierOCR) {
                    return 'tiersInvoiceSupplierControl'
                }
                return ''
            }
        },
        watch:{
            '$route' (to, from) {
                const index = this.tabsToReload.indexOf(to.name)
                
				if(index > -1) {
                    this.$emit(to.name + "::reloadTabs")
                    this.tabsToReload.splice(index, 1)
                }
            },
			"$route.params.tiers_id" (val) {
				this.tiers_id = val
				this.init_component()
			}
        },
		components: {
            Swiper: () => import('GroomyRoot/components/Swiper'),
            HeaderTab: () => import('@/components/HeaderTab'),
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
		}
	}
</script>
